import { createSlice } from '@reduxjs/toolkit';

/**
 * example type upload Item.
 * @typedef {{ file: File, progress: number, state: 'progress' | 'error' | 'done' }}
 *
 */

const initialState = {
	show: false,
	list: [],
};

const uploadSlice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		addUploadItem: (state, action) => {
			state.show = true;
			state.list.unshift({
				file: action.payload.file,
				progress: 0,
				state: 'progress',
			});
		},
		updateUploadItem: (state, action) => {
			const index = state.list.findIndex((item) => action.payload.file.name === item.file.name);
			state.list.splice(index, 1, action.payload);
		},
		toggleShowUpload: (state) => {
			state.show = !state.show;
		},
		closeShowUpload: (state, action) => {
			state.show = action.payload;
		},
	},
});

export const { addUploadItem, updateUploadItem, toggleShowUpload, closeShowUpload } = uploadSlice.actions;

export default uploadSlice.reducer;
